import '../../assets/styles/productCard.css'
import CrownLogo from '../../assets/images/crown.png'
import NetworkLogo from '../../assets/images/network.png'
import BidirecitonalLogo from '../../assets/images/bidirectionalArrows.png'
import WordpressLogo from '../../assets/images/wordpressLogo.png'

const ProductCard = ({ logo, title, text, linkText, colorCode }) => {
  const logoMap = new Map([
    ["crown", CrownLogo],
    ["network", NetworkLogo],
    ["bidirectionalArrows", BidirecitonalLogo],
    ["wordpressLogo", WordpressLogo],
  ])
  return (
    <div
      className={`productCard border-bott `}
      style={{
        ':after': {
          backgroundColor: colorCode
        }
      }}
    >
      <div className="main">
        <div className="logo">
          <img src={logoMap.get(logo)} alt="" className='h-16' />
        </div>
        <div className="heading text-2xl mt-10 mx-0 mb-[1.9rem] text-[2rem]">
          <p>{title}</p>
        </div>
        <div className="text">
          <p className='mb-[1.4rem] text-2xl leading-[2.4rem]'>
            {text}
          </p>
        </div>
      </div>
      <div className="learn_more block mt-4 text-2xl">
        <a href="" className=''>
          {linkText}
        </a>
      </div>
    </div>
  )
}

export default ProductCard
