import '../assets/styles/diffSlider.css'
import boatOriginal from '../assets/images/inpage_images/boat_original.png'
import boatCompressed from '../assets/images/inpage_images/boat-compressed.jpg'
import arrowLeft from '../assets/images/symbols/arrow_left.svg'
import arrowRight from '../assets/images/symbols/arrow_right.svg'
import arrowTR from '../assets/images/symbols/arrow_top_right.svg'

const DifferenceSlider = () => {
  return (
    <div className="chie1vm">
      <div className="header px-4">
        <h3>IMAGE COMPRESSION AND CONVERSION WITHOUT VISIBLE QUALITY LOSS</h3>
        <h2>Can you tell the difference?</h2>
        <p>Move the slider to compare the compressed and converted image with the original.
          <br />
          The file size is reduced by more than 85%!
        </p>
      </div>

      <div className="body">
        <div className="slider">
          <div>
            <img src={boatCompressed} className='rightImage' />
            <img src={boatOriginal} className='leftImage' />
            <div>
              <div id="innermostDiv1"></div>
              <div id="innermostDiv2">
                <div>
                  <div className='sliderLeftArrow'></div>
                  <div className='sliderRightArrow'></div>
                </div>
              </div>
              <div id="innermostDiv3"></div>
            </div>
          </div>
        </div>
        <div className="arrow left">
          <img src={arrowLeft} alt="arrow" />
          <div className="arrow-label">Darker places stay intact</div>
        </div>
        <div className="arrow right">
          <img src={arrowRight} alt="arrow" />
          <div className="arrow-label">Tiniest details are still there</div>
        </div>
        <div className="arrow top-right">
          <div className="arrow-label">Almost no difference in the sunset gradient</div>
          <img src={arrowTR} alt="arrow" />
        </div>
        <div className="label original">
          <div className="origin">ORIGINAL</div>
          <div className="filesize">1.1MB</div>
        </div>
        <div className="label tinify">
          <div className="origin">TINIFY</div>
          <div className="filesize">188 KB</div>
        </div>
      </div>
    </div>
  )
}

export default DifferenceSlider

