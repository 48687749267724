import '../assets/styles/trustedCompany.css';
import CompanyCard from './UI/CompanyCard';

const TrustedCompany = () => {
  return (
    <div className="trustedCompany mb-40 flex justify-between items-center py-0 px-20 relative w-full max-xxl:flex-col max-sm:py-0 max-sm:px-8">
      <div className="header">
        <p>
          Trusted by thousands of companies around the world
        </p>
      </div>
      <div className="blocks">
        <div className="column even">
          <CompanyCard companyName='airbnb' />
          <CompanyCard companyName='ask' />
          <CompanyCard companyName='microsoft' />
        </div>
        <div className="column odd">
          <CompanyCard companyName='hertz' />
          <CompanyCard companyName='peta' />
          <CompanyCard companyName='samsung' />
        </div>
        <div className="column even">
          <CompanyCard companyName='sony' />
          <CompanyCard companyName='ea' />
        </div>
      </div>
    </div>
  )
}

export default TrustedCompany

