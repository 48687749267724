import FText_Link from "./FText_Link"

const FooterColumn = ({ type, heading, arrayOfTextContent }) => {
  return (
    <div className="column flex flex-col gap-y-6">
      <div className="column-header font-bold gap-y-7 text-white">
        {heading}
      </div>
      <div className={`column-data ${type === "social-media-tags" && `flex ml:max-lg:flex-col gap-4 mt-3.5`}`}>
        {arrayOfTextContent.map(textContent => (
          <FText_Link text={textContent} />
        ))}
      </div>
    </div>
  )
}

export default FooterColumn
