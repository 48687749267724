import { useRef } from "react";
import Navbar from "./components/Navbar";
import CloudPic from './assets/images/inpage_images/cloud_home.webp'
import BambooPic from './assets/images/inpage_images/bamboo_isolated.webp'
import PandaEating from './assets/images/inpage_images/panda_eating_bamboo.webp'
import LongGrass from './assets/images/inpage_images/long_grass.webp'
import Button from "./components/UI/Button";
import ProductsSection from "./components/ProductsSection";
import TrustedCompany from "./components/TrustedCompany";
import DifferenceSlider from "./components/DifferenceSlider";
import AnalysisForm from "./components/AnalysisForm";
import PandaCord from "./components/PandaCord";
import Accordion from "./components/Accordion";
import Footer from "./components/Footer";
import TestinominalDiv from "./components/TestinominalDiv";
import TestinmonialCard from "./components/UI/TestinmonialCard";
import Dropbox from './assets/images/DropBox'
import Toggle from "./components/UI/Toggle";

function App() {
  const hiddenFileInput = useRef(null)
  return (
    <div className="App flex flex-col overflow-hidden">
      <Navbar />
      <main className="block">
        <div className="uploadDiv relative xxl:overflow-hidden max-md:h-[300px] md:max-lg:h-[500px] lg:h-[600px] w-full flex max-xxl:justify-start xxl:justify-center items-center">
          <img src={CloudPic} alt="" className="absolute top-0 left-0 w-[580px] h-[246px] ms-12 max-lg:hidden" />
          <img src={LongGrass} alt="" className="absolute bottom-0 right-[-20%] z-[2]" />
          <img src={PandaEating} alt="" className="absolute right-0 scale-x-[-1] bottom-0 max-xxs:w-[187.19px] max-xxs:h-[200px] xxs:w-[234px] xxs:h-[250px] md:w-[328px] md:h-[350px] lg:w-[417px] lg:h-[467px]" />
          <img src={BambooPic} alt="" className="absolute bottom-0 left-0 max-xxl:hidden" />
          <div className="mainContent absolute max-lg:top-0 max-lg:left-0 z-20 flex flex-col md:flex-row lg:flex-row lg:jusify-center lg:items-center lg:w-1/2 lg:h-1/2 lg:left-10 xxl:static">
            <div className="hero-container h-[300px] max-xs:w-4/6 xs:w-[37.5%] pt-5 px-5 lg:mr-[12.5%] text-wrap xxl:w-1/2">
              <h1 className="hero_title font-semibold tracking-[-.992px] xxl:text-7xl lg:max-xxl:text-5xl md:max-lg:text-5xl md:max-lg:p-0 md:max-lg:mb-12 max-md:text-4xl max-md:p-0 max-md:mb-8">
                Smart WebP, PNG and JPEG Compression for Faster Websites
              </h1>
              <div className="hero_subitleDiv flex gap-4 max-lg:hidden">
                <h2 className="hero_subtitle font-normal text-5xl leading-[48px]">
                  Unoptimize Now
                </h2>
                <svg ariaHidden="true" focusable="false" dataPrefix="fas" dataIcon="arrow-right" className="svg-inline--fa fa-arrow-right h-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path fill="currentColor" d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                </svg>
              </div>
              <div className="hero_button">
                <Button text="Go Web Pro" ></Button>
              </div>
            </div>
            <div className="uploadContainer relative p-8 h-[339px] lg:min-w-[510px] rounded-2xl z-20 bg-[#fff]/[.25] max-md:w-full md:max-lg:h-[298px] md:max-lg:w-[390px] md:max-lg:mx-10">
              <div
                className="uploadDropzone w-full h-full flex flex-col justify-center items-center border border-white border-dashed relative"
              >
                <div
                  className="mainSection w-full flex flex-col grow justify-center items-center gap-4 cursor-pointer"
                  onClick={() => hiddenFileInput.current.click()}
                >
                  <input type="file" title="" multiple="" accept=".jpg, .jpeg, .png, .webp, .dmg" ariaLabelledby="upload-dropbox-zone" className="h-full w-full z-30 uploadDocs hidden" ref={hiddenFileInput} />
                  <Dropbox />
                  <div className="text-wrapper">
                    <div className="header font-bold text-3xl">Upload your files here!</div>
                    <div className="subtext text-2xl">Up to 20 images, max 5 MB each.</div>
                  </div>
                </div>
                <div className="togglePart flex gap-4 items-center absolute bottom-0 bg-white w-full py-4 px-[1.1rem] h-[4.8rem] rounded-b-2xl mt-0">
                  <Toggle />
                  <p className="m-0">Convert my images automatically</p>
                </div>
              </div>
              <div className="toggleButton"></div>
            </div>
          </div>
        </div>
        <div className="extension h-[339px] w-full md:hidden" />
      </main>
      <div
        className="pageWidth relative mx-auto"
        style={{
          maxWidth: 'min(144rem, 100vw)'
        }}
      >
        <div className="content flex justify-center items-center flex-col gap-y-40 mt-[6.5rem] mx-0 mb-40 max-md:m-0 max-md:gap-0">
          <ProductsSection />
          <TrustedCompany />
          <DifferenceSlider />
          <AnalysisForm />
          {/* Skipped card carousal to be here */}
          <PandaCord />
          {/* <Accordion /> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;