import untinyPNGLogo from '../assets/images/tinify-navbar-logo.svg'
import ListItem from './UI/ListItem'

const Navbar = () => {
  return (
    <nav className="navbar flex h-32 w-full">
      <div className="logo ms-12 block h-28">
        <a href="/">
          <figure className="block figure m-0 mr-4 py-[1.8rem] px-0 w-48 cursor-pointer">
            <img src={untinyPNGLogo} alt="" className="w-full" />
          </figure>
        </a>
      </div>
      <div className="navlinks relative px-12 mx-auto max-w-[114rem] flex justify-between items-center ml:items-stretch max-lg:p-0  ">
        <section className='flex opacity-0 flex-col items-start fixed top-0 pt-40 px-13 pb-[20vh] right-full min-h-screen w-screen bg-white max-ml:justify-start ml:static ml:opacity-100 ml:grow ml:flex-row ml:justify-between ml:items-stretch ml:p-0 ml:w-auto ml:min-h-0'>
          <ul className='p-0 m-0 justify-center max-ml:list-none max-ml:h-fit max-ml:flex max-ml:flex-col max-ml:items-start max-ml:w-full max-ml:order-2 max-ml:mt-[10vh] ml:flex ml:translate-y-0 ml:grow ml:shrink ml:items-center ml:list-none '>
            <ListItem isActive={false} liValue={"Web"} />
            <ListItem isActive={false} liValue={"CDN"} />
            <ListItem isActive={false} liValue={"API"} />
            <ListItem isActive={false} liValue={"Third-Party"} />
            <ListItem isActive={false} liValue={"Web"} />
          </ul>
        </section>
        <div className="mobile-menu fixed top-0 right-0 z-[1] block text-[2rem] cursor-pointer ml:hidden">
          {/* <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="xmark" className="svg-inline--fa fa-xmark " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
            <path fill="currentColor" d="M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z" />
          </svg> */}
          {/* <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="bars" className="svg-inline--fa fa-bars absolute top-10 right-10 w-12 overflow-visible box-content inline-block h-[1em] align-[-0.125em]" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path fill="currentColor" d="M0 88C0 74.7 10.7 64 24 64H424c13.3 0 24 10.7 24 24s-10.7 24-24 24H24C10.7 112 0 101.3 0 88zM0 248c0-13.3 10.7-24 24-24H424c13.3 0 24 10.7 24 24s-10.7 24-24 24H24c-13.3 0-24-10.7-24-24zM448 408c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24s10.7-24 24-24H424c13.3 0 24 10.7 24 24z" />
          </svg> */}
        </div>
      </div>
    </nav>
  )
}

export default Navbar
