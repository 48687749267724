import FooterColumn from "./UI/Footer/FooterColumn"
import FooterLink from "./UI/Footer/FooterLink"
import Linkedin from '../assets/images/footer/footer_ln_logo.svg'
import Facebook from '../assets/images/footer/footer_fb_icon.svg'
import Github from '../assets/images/footer/footer_github_logo.svg'
import X from '../assets/images/footer/footer_x_logo.webp'
import FooterLogo from "./UI/Footer/FooterLogo"
import Voormedia from '../assets/images/footer/voormedia-logo-white.svg'

const Footer = () => {
  return (
    <div className="Footer mt-auto bg-[#40444f]">
      <div className="footerContent flex justify-center items-center relative mx-auto max-w-[144rem]">
        <div className="container flex flex-col justify-between py-28 px-20 max-ml:p-20 max-md:p-0">
          <div className="link-container flex justify-between py-16 px-0 max-md:flex-col max-md:items-center max-md:text-center max-md:gap-y-4 max-md:gap-x-0 max-ml:flex-wrap max-ml:gap-y-16 max-ml:gap-x-0">
            <FooterColumn
              type={"normal"}
              heading={"CDN"}
              arrayOfTextContent={["How it works", "Pricing", "Documentation"]}
            />
            <FooterColumn
              type={"normal"}
              heading={"Developer API"}
              arrayOfTextContent={["API reference", "Ruby gem", "PHP library", "Node.js package", "Python package", "JAVA component", ".NET client library"]}
            />
            <FooterColumn
              type={"normal"}
              heading={"OFFICIAL ADD-ONS"}
              arrayOfTextContent={["WordPress plugin", "Magento CDN plugin", "Zapier (beta)"]}
            />
            <FooterColumn
              type={"normal"}
              heading={"OTHER"}
              arrayOfTextContent={["Third-party solutions", "Page analyzer", "Blog", "Knowledge base articles", "Contact support", "Donate"]}
            />
            <FooterColumn
              type={"social-media-tags"}
              heading={"FOLLOW US"}
              arrayOfTextContent={[
                <FooterLink logo={<FooterLogo source={Linkedin} />} hrefLink="https://www.linkedin.com/company/tinypng/" />,
                <FooterLink logo={<FooterLogo source={X} />} hrefLink="https://twitter.com/Tinypng" />,
                <FooterLink logo={<FooterLogo source={Facebook} />} hrefLink="https://www.facebook.com/TinyPNG/" />,
                <FooterLink logo={<FooterLogo source={Github} />} hrefLink="https://github.com/tinify" />,
              ]}
            />
          </div>
          <div className="bottom-container flex justify-between items-center border-t-4 border-solid border-[#a1b7bf] text-white max-md:pt-8 max-md:px-0 max-md:pb-12 max-md:my-0 max-md:mx-12 max-lg:py-8 max-lg:px-0 max-lg:flex-col ml:pt-16 ml:flex-row">
            <div className="footerColumn made-by max-lg:pb-8 max-lg:order-2 max-lg:border-b-4 max-lg:border-solid max-lg:border-[#a1b7bf]">
              <span className="mr-3">Made by</span>
              <a href="https://www.voormedia.com/">
                <img src={Voormedia} alt="" width="104px" height="15px" />
              </a>
            </div>
            <div className="footerColumn disclaimer max-lg:order-1 max-lg:mb-8">
              <p>By using this site you accept the terms of use.</p>
            </div>
            <div className="footerColumn langauges max-lg:order-3 max-lg:pt-8">
              <div className="languages flex gap-y-0 gap-x-7">
                <a href="" className="text-[#a1b7bf]">
                  <svg ariaHidden="true" focusable="false" dataPrefix="fas" dataIcon="location-dot" className="svg-inline--fa fa-location-dot " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <path fill="currentColor" d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                  </svg>
                  <span>English</span>
                </a>
                <a href="" className="text-[#a1b7bf]">
                  <svg ariaHidden="true" focusable="false" dataPrefix="fas" dataIcon="location-dot" className="svg-inline--fa fa-location-dot " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <path fill="currentColor" d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                  </svg>
                  <span>简体</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
