import '../../assets/styles/button.css';

const Button = ({ text = '' }) => {
  return (
    <a className="button">
      {text}
    </a>
  )
}

export default Button