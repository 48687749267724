import '../assets/styles/pandaCord.css'
import pandaWithCord from '../assets/images/inpage_images/panda_with_cord.webp'
import shortGrass from '../assets/images/inpage_images/grass_short.webp'

const PandaCord = () => {
  return (
    <div className="cungxf7">
      <div className="container">
        <div className="body">
          <h3>
            Speed up your
            <br />
            website with Tinify
          </h3>
          <p>
            Maximize your website’s performance
            <br />
            and improve SEO rankings with
            <br />
            Tinify’s image optimization.
          </p>
        </div>
        <div className="panda-dummy">
        </div>
        <picture>
          <source media="(max-width: 640px)" srcset={pandaWithCord} height={'241px'} width={'241px'} />
          <source media="(min-width: 640px) and (max-width: 1235px)" srcset={pandaWithCord} height={'300px'} width={'300px'} />
          <img className="panda" src={pandaWithCord} alt="Panda with electrical cord" width="481" height="481" />
        </picture>
        <img className="grass" width="1080" height="47" src={shortGrass} alt="Grass" />
      </div>
    </div>
  )
}

export default PandaCord
