import '../../assets/styles/companyCard.css';
import airbnb from '../../assets/images/companies/airbnb_logo.svg';
import ask from '../../assets/images/companies/ask_com_logo.webp';
import microsoft from '../../assets/images/companies/microsoft_logo.svg';
import hertz from '../../assets/images/companies/hertz_logo.webp';
import peta from '../../assets/images/companies/peta_logo.svg';
import samsung from '../../assets/images/companies/samsung_logo.svg';
import sony from '../../assets/images/companies/sony-logo.webp';
import ea from '../../assets/images/companies/sony-logo.webp';

const CompanyCard = ({ companyName = '' }) => {
  const fruits = new Map([
    ['airbnb', airbnb],
    ['ask', ask],
    ['microsoft', microsoft],
    ['hertz', hertz],
    ['peta', peta],
    ['samsung', samsung],
    ['sony', sony],
    ['ea', ea],
  ]);
  return (
    <div className="companyCard card">
      <div className="main">
        <img src={fruits.get(companyName)} />
      </div>
    </div>
  )
}

export default CompanyCard
