import ProductCard from "./UI/ProductCard";

const ProductsSection = () => {
  return (
    <div className="productsSection flex flex-col items-center w-full max-md:pt-20 max-md:px-12 max-md:pb-0 md:py-0 md:px-20">
      <div className="header flex flex-col justify-center items-center text-center max-w-[96rem] mb-16">
        <h3 className="mb-6 font-bold tracking-widest uppercase text-[1.3rem] text-[#0692e5]">
          OUR PRODUCTS
        </h3>
        <h2 className="font-bold text-[4rem] leading-[4.5rem] tracking-[-.1rem] mb-6 font-['THICCCBOI']">
          Optimization for each project
        </h2>
        <p className="text-[1.8rem] leading-[3rem] mb-[1.4rem]">
          Tailored solutions for website owners, developers, and designers, ensuring optimal website performance for every project. Discover the advantages of faster loading times with our image optimization tools.
        </p>
      </div>
      <div className="blocks flex flex-wrap last:justify-center max-lg:flex-col">
        <ProductCard
          logo={"crown"}
          title={<><strong>Web Pro</strong> and <strong>Web Ultra</strong></>}
          text={"The online compressor empowers you to easily optimize your images. Seamlessly convert to WebP or efficiently compress extensive batches to minimize file sizes, all with ease."}
          linkText={"Buy Now"}
          colorCode={'#8CC939'}
        />
        <ProductCard
          logo={"network"}
          title={<>Untinify <strong>CDN</strong></>}
          text={"Power up your website with Tinify's Image CDN. Harness the power of multiple content delivery networks along with advanced image optimization for unmatched reliability and performance."}
          linkText={"Learn more"}
          colorCode={'#F08517'}
        />
        <ProductCard
          logo={"bidirectionalArrows"}
          title={<><strong>API</strong> Integration</>}
          text={"Integrate Tinify’s powerful image API seamlessly into your workflow. Explore advanced features like resizing, converting, and cropping for a comprehensive experience."}
          linkText={"Learn more"}
          colorCode={'#8B65DC'}
        />
        <ProductCard
          logo={"wordpressLogo"}
          title={<><strong>Wordpress</strong> plugin</>}
          text={"Accelerate your WordPress website with the TinyPNG plugin. Automated image compression for fast page load times and enhanced user experience."}
          linkText={"Go to WP plugin"}
          colorCode={'#3E8EC0'}
        />
      </div>
    </div>
  )
}

export default ProductsSection
