const ListItem = ({ isActive = false, liValue = '' }) => {
  return (
    <li className={`${isActive && 'min-ml:font-bold'} relative list-none p-0 m-0 list-item`}>
      <a className="relative flex items-center py-6 pr-8 pl-0 h-full text-[2rem] whitespace-nowrap ml:py-0 ml:px-8 ml:text-[1.9rem]">
        <span>
          {liValue}
        </span>
      </a>
    </li>
  )
}

export default ListItem
