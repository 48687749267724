const FooterLink = ({ logo, hrefLink = '/' }) => {
  return (
    <a
      href={hrefLink}
      className="bac w-[5.4rem] h-[5.4rem] rounded-2xl flex justify-center items-center border border-solid border-white/[0.2]"
      style={{
        border: "1px solid hsla(0, 0%, 100%, .2)",
        background: "hsla(0, 0%, 100%, .1)",
      }}
    >
      {logo}
    </a >
  )
}

export default FooterLink
